/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

import Custom404 from 'pages/404'

const Request = () => {
  const router = useRouter()
  const [invalidRequest, setInvalidRequest] = useState<boolean>(false)

  useEffect(() => {
    if (router.query) {
      const { requestId } = router.query
      if (requestId) {
        const query = { requestId }

        if (typeof requestId === 'string' || requestId instanceof String) {
          if (requestId.startsWith('pr_')) {
            setInvalidRequest(false)
            router.replace({ pathname: '/', query }, `/${requestId}`)
          } else {
            setInvalidRequest(true)
          }
        } else {
          setInvalidRequest(true)
        }
      }
    }
  }, [router])

  return (
    <div>
      <Head>
        <title>Magpie Payment Request</title>
        <meta name="robots" content="noindex" />
      </Head>
      {invalidRequest && <Custom404 />}
    </div>
  )
}

export default Request
